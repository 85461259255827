import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Role } from '../enums/role';
import { User } from '../models/auth.models';
import { SearchService } from './search.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // user: User;

  // constructor() {
  // }

  // /**
  //  * Returns the current user
  //  */
  // public currentUser(): User {
  //     return getFirebaseBackend().getAuthenticatedUser();
  // }

  // /**
  //  * Performs the auth
  //  * @param email email of user
  //  * @param password password of user
  //  */
  // login(email: string, password: string) {
  //     return getFirebaseBackend().loginUser(email, password).then((response: any) => {
  //         const user = response;
  //         return user;
  //     });
  // }

  // /**
  //  * Performs the register
  //  * @param email email
  //  * @param password password
  //  */
  // register(email: string, password: string) {
  //     return getFirebaseBackend().registerUser(email, password).then((response: any) => {
  //         const user = response;
  //         return user;
  //     });
  // }

  // /**
  //  * Reset password
  //  * @param email email
  //  */
  // resetPassword(email: string) {
  //     return getFirebaseBackend().forgetPassword(email).then((response: any) => {
  //         const message = response.data;
  //         return message;
  //     });
  // }

  // /**
  //  * Logout the user
  //  */
  // logout() {
  //     // logout the user
  //     getFirebaseBackend().logout();
  // }

  apiUrl = environment.apiUrl;
  token;
  authHeader;

  appAllowedRoles = [255, 1];

  //public
  public currentUser: Observable<User>;

  //private
  public currentUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentUserPremissions: BehaviorSubject<any> = new BehaviorSubject(
    null,
  );
  userById: BehaviorSubject<any> = new BehaviorSubject(null);

  getUserById;

  userProcess: BehaviorSubject<boolean> = new BehaviorSubject(null);
  userRole: BehaviorSubject<any> = new BehaviorSubject(null);
  userPremission: BehaviorSubject<any> = new BehaviorSubject(null);

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _router: Router,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')),
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get userPremissions(): any {
    return this.currentUserPremissions.value;
  }

  public get userByIdValue(): any {
    return this.userById.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(user) {
    return this._http
      .post<any>(`${environment.apiUrl}Account/Login`, user)
      .pipe(
        switchMap((res: any) => {
          if (res?.duoLogin) {
            window.open(`${res?.duoUri}`, '_self');
            return of(false);
            // do logic here
          } else {
            return of(res);
          }
        }),
        map((user: any) => {
          if (user) {
            if (this.appAllowedRoles.indexOf(user?.userType) > -1) {
              this.currentUserSubject.next(user);
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('token', JSON.stringify(user?.token));
              this.token = user?.token;
              this.prepareToken();
            } else {
              this._toastrService.error(
                "user isn't authorized to login",
                'error',
              );
            }
          }
          return user;
        }),
      );
  }

  LDAPlogin(user) {
    return this._http
      .post<any>(`${environment.apiUrl}Account/LDAPAuth`, user)
      .pipe(
        switchMap((res: any) => {
          if (res?.duoLogin) {
            window.open(`${res?.duoUri}`, '_self');
            return of(false);
            // do logic here
          } else {
            return of(res);
          }
        }),
      );
  }

  DetectUserRoute() {
    const userType = this.userRole.value;
    switch (userType) {
      case Role.Admin:
        this._router.navigate([Role.AdminUrl]);
        break;
      case Role.User:
        this._router.navigate([Role.UserUrl]);
        break;
      default:
        // this._toastrService.error("user isn't authorized to login", 'error');
        this.logout();
        this._router.navigate(['/account/login']);
        break;
    }
  }

  forget(email) {
    return this._http.post<any>(`${environment.apiUrl}/get-code`, email).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  prepareToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      const role = +jwt_decode(token)['UserType'];
      ////////console.log(jwt_decode(token)["role"]);
      this.currentUserPremissions.next(jwt_decode(token)['role']);
      ////console.log(jwt_decode(token)["role"])
      // ////console.log(jwt_decode(token)["role"])
      this.userRole.next(role);
      // //////console.log(role)
      this.token = `Bearer ${token}`;
      this.authHeader = new HttpHeaders({
        Authorization: `${this.token}`,
      });
    }
  }

  autoLogin() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    // this.userRoles.next(role);
    // //////console.log(jwt_decode(user.token))
    // //////console.log(jwt_decode(user.token)['role'])

    if (user) {
      // const role = jwt_decode(user.token)['role'];
      // ////////console.log(role)
      this.prepareToken();
      this.verifyToken();
      this.currentUserSubject.next(user);
      // this._router.navigate(['/products'])
    }
  }

  verifyToken() {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      const tokenDecode = jwt_decode(token);
      const expireDate = tokenDecode['exp'] * 1000;
      const nowDate = new Date().getTime();
      if (nowDate > expireDate) {
        this.logout();
      }
    }
  }

  signUp(user) {
    return this._http.post(`${this.apiUrl}/register`, user);
  }

  validateCode(user) {
    return this._http
      .post<any>(`${environment.apiUrl}/check-email-code`, user)
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  resetPassword(user) {
    return this._http
      .post<any>(`${environment.apiUrl}/reset-password`, user)
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  switchUser(depId) {
    return this._http
      .post<any>(
        `${environment.apiUrl}Account/SwitchDepartment/${depId}`,
        {},
        { headers: this.authHeader },
      )
      .pipe(
        map((user: any) => {
          if (user) {
            ////////console.log(user)
            if (this.appAllowedRoles.indexOf(user?.userType) > -1) {
              this.currentUserSubject.next(user);
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('token', JSON.stringify(user?.token));
              this.token = user?.token;
              this.prepareToken();
            } else {
              this._toastrService.error(
                "user isn't authorized to login",
                'error',
              );
            }
          }
          return user;
        }),
      );
  }

  switchDelegations(depId, userId) {
    return this._http
      .post<any>(
        `${environment.apiUrl}Account/DelgationToken/${userId}/${depId}`,
        {},
        { headers: this.authHeader },
      )
      .pipe(
        map((user: any) => {
          if (user) {
            ////////console.log(user)
            if (this.appAllowedRoles.indexOf(user?.userType) > -1) {
              this.currentUserSubject.next(user);
              localStorage.setItem('currentUser', JSON.stringify(user));
              localStorage.setItem('token', JSON.stringify(user?.token));
              this.token = user?.token;
              this.prepareToken();
            } else {
              this._toastrService.error(
                "user isn't authorized to login",
                'error',
              );
            }
          }
          return user;
        }),
      );
  }

  /**
   * User logout
   *
   */

  logout() {
    this._http
      .get(`${this.apiUrl}account/Logout`, { headers: this.authHeader })
      .subscribe(
        (res: any) => {
          if (res?.active) {
            this.clearStorage();
            window.open(`${res?.logoutUrl}`, '_self');
          } else {
            this.clearStorage();
            this._router.navigate(['/account/login']);
          }
        },
        (err) => {
          this.clearStorage();
          this._router.navigate(['/account/login']);
        },
      );
  }

  clearStorage() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('lastTransaction');
    localStorage.removeItem('allowHijri');
    localStorage.removeItem('allowdExtenesion');
    // localStorage.removeItem('config');
    // notify
    this.currentUserSubject.next(null);
    this.userRole.next(null);
    this.currentUserPremissions.next(null);
    //
  }
}
