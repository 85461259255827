import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ExDepartmentService {
  apiUrl = environment.apiUrl;

  constructor(
    private _authService: AuthenticationService,
    private _httpClient: HttpClient,
  ) {}

  createExternalDepartment(department: any) {
    return this._httpClient.post(
      `${this.apiUrl}Admin/CreateExternalDepartment`,
      department,
      { headers: this._authService.authHeader },
    );
  }

  updateExternalDepartment(department: any) {
    return this._httpClient.put(
      `${this.apiUrl}Admin/UpdateExternalDepartment`,
      department,
      { headers: this._authService.authHeader },
    );
  }

  getExternalDepartments(limit?, page?, filter?) {
    if (limit && page) {
      return this._httpClient.get(
        `${this.apiUrl}Admin/GetExternalDepartments${
          limit ? '?' + 'pageSize=' + limit : ''
        }${page || page === 0 ? '&' + 'page=' + page : ''}${
          filter ? '&' + 'search=' + `${filter}` : ''
        }`,
        { headers: this._authService.authHeader },
      );
    } else {
      return this._httpClient.get(
        `${this.apiUrl}Admin/GetListExternalDepartments${
          limit ? '?' + 'pageSize=' + limit : ''
        }${page || page === 0 ? '&' + 'page=' + page : ''}${
          filter ? '&' + 'search=' + `${filter}` : ''
        }`,
        { headers: this._authService.authHeader },
      );
    }
  }

  // /api/Admin/GetFlatExternalDepartments

  getFlatExternalDepartments(limit?, page?, filter?) {
    return this._httpClient.get(
      `${this.apiUrl}Admin/GetFlatExternalDepartments${
        limit ? '?' + 'pageSize=' + limit : ''
      }${page || page === 0 ? '&' + 'page=' + page : ''}${
        filter ? '&' + 'search=' + `${filter}` : ''
      }`,
      { headers: this._authService.authHeader },
    );
  }

  getExternalDepartment(id: any) {
    return this._httpClient.get(
      `${this.apiUrl}Admin/GetExternalDepartment/${id}`,
      { headers: this._authService.authHeader },
    );
  }

  deleteExternalDepartment(id: any) {
    return this._httpClient.delete(
      `${this.apiUrl}Admin/DeleteExternalDepartment?id=${id}`,
      { headers: this._authService.authHeader },
    );
  }

  getNewExterlnalDepartments() {
    return this._httpClient.get(
      `${this.apiUrl}Admin/getlistexternaldepartments`,
      { headers: this._authService.authHeader },
    );
  }
}
