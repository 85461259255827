export const permissions = {
  //https://dev.signalx.42.guru/inbound
  inbound: [
    'Create_Inward',
    'Transaction_Lookup_Access',
    'View_ExternalEntities',
    'View_LetterTypes',
    'View_TransactionClassification',
    'View_Attachements',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/outbound
  internalOutbound: [
    'Create_Internal',
    'Transaction_Lookup_Access',
    'View_ExternalEntities',
    'View_LetterTypes',
    'View_TransactionClassification',
    'View_Attachements',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/outbound

  Outbound: [
    'Create_Outward',
    'Transaction_Lookup_Access',
    'View_ExternalEntities',
    'View_LetterTypes',
    'View_TransactionClassification',
    'View_Attachements',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/outbound

  outboundDraft: [
    'Create_Outward',
    'Transaction_Lookup_Access',
    'View_ExternalEntities',
    'View_LetterTypes',
    'View_TransactionClassification',
    'View_Attachements',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/inbox

  inbox: ['View_My_Inbox', 'View_Organization_Structure', 'View_Users'],
  //https://dev.signalx.42.guru/secretary-box

  secretaryBox: [
    'View_Seceratry_Inbox',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/copies
  copies: ['View_ECopies_Inbox', 'View_Organization_Structure', 'View_Users'],
  //https://dev.signalx.42.guru/outbound-box

  outboundBox: [
    'View_OutBound_Box',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/archive

  archiveBox: [
    'View_Archive_Inbox',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/sent

  sendBox: [
    'View_Sent_Transactions_Inbox',
    'View_Organization_Structure',
    'View_Users',
  ],

  //https://dev.signalx.42.guru/following

  followingBox: [
    'View_FollowUp_Inbox',
    'View_Organization_Structure',
    'View_Users',
  ],
  managerBox: ['View_Manager_Inbox'],
  //https://dev.signalx.42.guru/draft-box
  draftBox: ['View_Organization_Structure', 'View_Users'],
  //https://dev.signalx.42.guru/delivery-statement
  deliveryStatement: [
    'SearchDeliveryStatement',
    'SearchPrintedDeliveryStatement',
    'SearchArchivedDeliveryStatement',
    'PrintDeliveryStatement',
    'ArchivedDeliveryStatement',
  ],
  //https://dev.signalx.42.guru/reports
  reports: [
    'SearchReports',
    'View_My_Inbox',
    'View_Seceratry_Inbox',
    'View_Sent_Transactions_Inbox',
    'View_Organization_Structure',
    'View_Users',
  ],
  //https://dev.signalx.42.guru/tasks
  tasks: ['View_My_Inbox'],
  sendCopy: ['Transaction_SendCopy'],
  forwardInbound: ['Transaction_Forword'],
  AnalyticsDashboard: ['AnalyticsDashboard'],
  transactionLogs: ['Transaction_log'],

  reservedTransactins: ['View_ReservedTransactionNumber'],
};
enum RoleEnum {
  //Attachements Lookups
  View_Attachements = 1,
  Add_Value_to_Attachements = 2,
  Edit_Attachements = 3,
  Delete_Value_from_Attachements = 4,
  Lock_Unlock_Attachements = 5,
  Activate_Inactivate_Attachements = 6,

  //Letter Types Lookups
  View_LetterTypes = 7,
  Add_Value_to_LetterTypes = 8,
  Edit_LetterTypes = 9,
  Delete_Value_from_LetterTypes = 10,
  Lock_Unlock_LetterTypes = 11,
  Activate_Inactivate_LetterTypes = 12,

  //Subject Category Lookups
  View_SubjectCategory = 13,
  Add_Value_to_SubjectCategory = 14,
  Edit_SubjectCategory = 15,
  Delete_Value_from_SubjectCategory = 16,
  Lock_Unlock_SubjectCategory = 17,
  Activate_Inactivate_SubjectCategory = 18,

  //Confidentiality Lookups
  View_Confidentiality = 19,
  Add_Value_to_Confidentiality = 20,
  Edit_Confidentiality = 21,
  Delete_Value_from_Confidentiality = 22,
  Lock_Unlock_Confidentiality = 23,
  Activate_Inactivate_Confidentiality = 24,

  //Confidentiality Level Lookups
  View_Secret_Transactions_Explanation = 25,
  View_Top_Secret_Transactions_Explanation = 26,
  View_Normal_Transactions_Explanation = 27,
  View_Hand_Delivered_Transactions_Explanation = 28,

  //External Entities Lookups
  View_ExternalEntities = 29,
  Add_Value_to_ExternalEntities = 30,
  Edit_ExternalEntities = 31,
  Delete_Value_from_ExternalEntities = 32,
  View_External_Entity_Affiliates = 33,
  Add_External_Entity_Affiliates = 34,
  Edit_External_Entity_Affiliates = 35,
  Delete_External_Entity_Affiliates = 36,

  //Templates Table
  View_Templates = 37,
  Add_Value_to_Templates = 38,
  Edit_Templates = 39,
  Delete_Value_from_Templates = 40,
  Hide_template = 41,
  Assign_templates_department = 42,

  //Reports Table
  View_Transaction_Report = 43,
  Print_Report = 44,
  Export_Report_Normal = 45,
  View_Performance_Report = 46,
  Export_Report_Performance = 47,

  //User settings Table
  Change_Language = 48,
  Signature = 49,
  Stamp = 50,
  Assignment_Sheet = 51,
  DistributionList = 52,

  //Search Roles
  Transaction_log = 53,
  Export_Serach_Results = 54,
  Transaction_Barcode_Priniting = 55,
  Transaction_certificate_history = 56,
  Transaction_Check_Ticket_Printing = 57,

  Search_Transactions_level_all_departments_divisions = 58,
  Search_Assigned_Transactions_OR_referred_employee_Unit_Staff_divisions_Department = 59,
  Search_Assigned_Transactions_OR_referred_employee_Unit_Staff = 60,
  Search_Assigned_Transactions_OR_referred_employee_Only = 61,

  //Inboxes Roles
  View_My_Inbox = 62,
  View_Seceratry_Inbox = 63,
  View_Tasks_Inbox = 64,

  View_Sent_Transactions_Inbox = 65,
  View_FollowUp_Inbox = 67,
  View_Archive_Inbox = 68,

  View_Shared_inbox = 69,
  View_ECopies_Inbox = 70,
  View_Manager_Inbox = 71,

  //Users Roles
  View_Users = 72,
  Add_Users = 73,
  Edit_Users = 74,
  Delete_Users = 75,
  Active_DeActivate_Users = 76,
  Add_Roles_To_User = 77,

  //Roles Table
  View_Roles = 78,
  Add_Roles = 79,
  Edit_Roles = 80,
  Delete_Roles = 81,
  Active_DeActivate_Roles = 82,

  //Departments Table
  View_Organization_Structure = 83,
  Add_Departments = 84,
  Edit_Departments = 85,
  Delete_Departments = 86,
  Transfer_Emplyee_From_Department = 87,
  View_Emplyee_From_Department = 88,
  Delete_Emplyee_From_Department = 89,

  //OutboundTransactions Box
  OutboundTransactions_Access = 90,
  Create_Outward = 91,
  Edit_Outward = 92,
  Last_Outward_Copying = 93,
  Create_Letter_Draft = 94,
  Edit_Letter_Draft = 95,
  Create_Outward_From_Letter_Draft = 96,
  Create_Letter_Draft_Inward_or_Internal = 97,
  Delete_Letter_Draft = 98,

  //InboudTransactions Box
  InboudTransactions_Access = 99,
  Create_Inward = 100,
  Create_Inward_Copying_Last_Inward = 101,
  Edit_Inward = 102,

  //InternalTransactions Box
  InternalTransactions_Access = 103,
  Create_Internal = 104,
  Create_Internal_ECopy = 105,
  Create_Internal_Copyig_Last_Internal = 106,
  Edit_Internal = 107,

  //TransactionRecord Box
  TransactionRecord_Access = 108,
  View_Stakeholders_Information_Record = 109,
  View_Transactions_Assignment_Record = 110,
  View_Transation_Letter_Attachements_Record = 111,
  Internal_ECopies_Record = 112,
  External_ECopies_Record = 113,
  Linked_Transactions_Record = 114,
  Explanations_Record = 115,
  FollowUp_Record = 116,
  TasksRecord = 117,
  Transaction_Tracking = 118,
  Transaction_Modification = 119,

  //PriorityLevel Lookups
  View_PriorityLevel = 120,
  Add_Value_to_PriorityLevel = 121,
  Edit_PriorityLevel = 122,
  Delete_Value_from_PriorityLevel = 123,
  Lock_Unlock_PriorityLevel = 124,
  Activate_Inactivate_PriorityLevel = 125,

  //TransactionClassification Lookups
  View_TransactionClassification = 126,
  Add_Value_to_TransactionClassification = 127,
  Edit_TransactionClassification = 128,
  Delete_Value_from_TransactionClassification = 129,
  Lock_Unlock_TransactionClassification = 130,
  Activate_Inactivate_TransactionClassification = 131,
  View_OutBound_Box = 132,
  Transaction_Lookup_Access = 133,
  ConfidentialityAccessing_Normal = 134,
  ConfidentialityAccessing_Secret = 135,
  ConfidentialityAccessing_TopSecret = 136,
  ConfidentialityAccessing_ByHand = 137,
  Transaction_Send = 138,
  Transaction_Forword = 139,
  Transaction_Rollback = 140,
  Transaction_UnSaveTransaction = 141,
  Transaction_SendCopy = 142,
  Transaction_Received = 143,
  Transaction_Reject = 144,
  Transaction_Archive = 145,
  AdvancedSearch = 146,
  SearchReports = 147,
  Transaction_Copy_UnSeen = 148,
  Transaction_Save_Modification = 149,
  View_Group = 150,
  Add_Value_to_Group = 151,
  Edit_Group = 152,
  Delete_Value_from_Group = 153,
  SearchDeliveryStatement = 154,
  SearchPrintedDeliveryStatement = 155,
  SearchArchivedDeliveryStatement = 156,
  PrintDeliveryStatement = 157,
  ArchivedDeliveryStatement = 158,
  Transaction_Share = 159,

  //Delegation
  View_Delegation = 160,
  Add_Value_to_Delegation = 161,
  Edit_Delegation = 162,
  Delete_Value_from_Delegation = 163,
  //charts
  View_Organization_Chart = 164,
  View_SubDepartment_Chart = 165,
  View_Department_Users_Chart = 166,
  View_User_Chart = 167,

  //BCC

  Transaction_MAKE_BCC = 169,
  Transaction_View_BCC_IN_Certificate = 168,

  //delete outbound

  Transaction_OutDelete = 170,
  Transaction_Delete = 187,

  //rollback after view
  Transaction_SpecialUnSend = 171,

  //statistics
  AnalyticsDashboard = 172,

  //editTransactionAfterSend
  Transaction_EditForce = 174,

  Transaction_Explanations_log = 176,

  Transaction_Views = 177,

  Transaction_History = 180,
  Transaction_Referrals = 178,

  TransactionPrint = 188,
  AttchmentsViewWithoutWaterMark = 189,

  View_ReservedTransactionNumber = 183,
}

export enum permissionsStrings {
  View_PriorityLevel = 'View_PriorityLevel',
  Add_Value_to_PriorityLevel = 'Add_Value_to_PriorityLevel',
  Edit_PriorityLevel = 'Edit_PriorityLevel',
  Delete_Value_from_PriorityLevel = 'Delete_Value_from_PriorityLevel',
  Lock_Unlock_PriorityLevel = 'Lock_Unlock_PriorityLevel',
  Activate_Inactivate_PriorityLevel = 'Activate_Inactivate_PriorityLevel',

  View_TransactionClassification = 'View_TransactionClassification',
  Add_Value_to_TransactionClassification = 'Add_Value_to_TransactionClassification',
  Edit_TransactionClassification = 'Edit_TransactionClassification',
  Delete_Value_from_TransactionClassification = 'Delete_Value_from_TransactionClassification',
  Lock_Unlock_TransactionClassification = 'Lock_Unlock_TransactionClassification',
  Activate_Inactivate_TransactionClassification = 'Activate_Inactivate_TransactionClassification',

  View_Attachements = 'View_Attachements',
  Add_Value_to_Attachements = 'Add_Value_to_Attachements',
  Edit_Attachements = 'Edit_Attachements',
  Delete_Value_from_Attachements = 'Delete_Value_from_Attachements',
  Lock_Unlock_Attachements = 'Lock_Unlock_Attachements',
  Activate_Inactivate_Attachements = 'Activate_Inactivate_Attachements',

  View_LetterTypes = 'View_LetterTypes',
  Add_Value_to_LetterTypes = 'Add_Value_to_LetterTypes',
  Edit_LetterTypes = 'Edit_LetterTypes',
  Delete_Value_from_LetterTypes = 'Delete_Value_from_LetterTypes',
  Lock_Unlock_LetterTypes = 'Lock_Unlock_LetterTypes',
  Activate_Inactivate_LetterTypes = 'Activate_Inactivate_LetterTypes',

  View_SubjectCategory = 'View_SubjectCategory',
  Add_Value_to_SubjectCategory = 'Add_Value_to_SubjectCategory',
  Edit_SubjectCategory = 'Edit_SubjectCategory',
  Delete_Value_from_SubjectCategory = 'Delete_Value_from_SubjectCategory',
  Lock_Unlock_SubjectCategory = 'Lock_Unlock_SubjectCategory',
  Activate_Inactivate_SubjectCategory = 'Activate_Inactivate_SubjectCategory',

  View_Confidentiality = 'View_Confidentiality',
  Add_Value_to_Confidentiality = 'Add_Value_to_Confidentiality',
  Edit_Confidentiality = 'Edit_Confidentiality',
  Delete_Value_from_Confidentiality = 'Delete_Value_from_Confidentiality',
  Lock_Unlock_Confidentiality = 'Lock_Unlock_Confidentiality',
  Activate_Inactivate_Confidentiality = 'Activate_Inactivate_Confidentiality',

  View_Secret_Transactions_Explanation = 'View_Secret_Transactions_Explanation',
  View_Top_Secret_Transactions_Explanation = 'View_Top_Secret_Transactions_Explanation',
  View_Normal_Transactions_Explanation = 'View_Normal_Transactions_Explanation',
  View_Hand_Delivered_Transactions_Explanation = 'View_Hand_Delivered_Transactions_Explanation',

  View_ExternalEntities = 'View_ExternalEntities',
  Add_Value_to_ExternalEntities = 'Add_Value_to_ExternalEntities',
  Edit_ExternalEntities = 'Edit_ExternalEntities',
  Delete_Value_from_ExternalEntities = 'Delete_Value_from_ExternalEntities',
  View_External_Entity_Affiliates = 'View_External_Entity_Affiliates',
  Add_External_Entity_Affiliates = 'Add_External_Entity_Affiliates',
  Edit_External_Entity_Affiliates = 'Edit_External_Entity_Affiliates',
  Delete_External_Entity_Affiliates = 'Delete_External_Entity_Affiliates',

  View_Templates = 'View_Templates',
  Add_Value_to_Templates = 'Add_Value_to_Templates',
  Edit_Templates = 'Edit_Templates',
  Delete_Value_from_Templates = 'Delete_Value_from_Templates',
  Hide_template = 'Hide_template',
  Assign_templates_department = 'Assign_templates_department',

  View_Transaction_Report = 'View_Transaction_Report',
  Print_Report = 'Print_Report',
  Export_Report_Normal = 'Export_Report_Normal',
  View_Performance_Report = 'View_Performance_Report',
  Export_Report_Performance = 'Export_Report_Performance',

  Change_Language = 'Change_Language',
  Signature = 'Signature',
  Stamp = 'Stamp',
  Assignment_Sheet = 'Assignment_Sheet',
  DistributionList = 'DistributionList',

  Transaction_log = 'Transaction_log',
  Export_Serach_Results = 'Export_Serach_Results',
  Transaction_Barcode_Priniting = 'Transaction_Barcode_Priniting',
  Transaction_certificate_history = 'Transaction_certificate_history',
  Transaction_Check_Ticket_Printing = 'Transaction_Check_Ticket_Printing',

  Search_Transactions_level_all_departments_divisions = 'Search_Transactions_level_all_departments_divisions',
  Search_Assigned_Transactions_OR_referred_employee_Unit_Staff_divisions_Department = 'Search_Assigned_Transactions_OR_referred_employee_Unit_Staff_divisions_Department',
  Search_Assigned_Transactions_OR_referred_employee_Unit_Staff = 'Search_Assigned_Transactions_OR_referred_employee_Unit_Staff',
  Search_Assigned_Transactions_OR_referred_employee_Only = 'Search_Assigned_Transactions_OR_referred_employee_Only',

  Transaction_Lookup_Access = 'Transaction_Lookup_Access',
  View_OutBound_Box = 'View_OutBound_Box',
  View_My_Inbox = 'View_My_Inbox',
  View_Seceratry_Inbox = 'View_Seceratry_Inbox',
  View_Tasks_Inbox = 'View_Tasks_Inbox',

  View_Sent_Transactions_Inbox = 'View_Sent_Transactions_Inbox',
  View_FollowUp_Inbox = 'View_FollowUp_Inbox',
  View_Archive_Inbox = 'View_Archive_Inbox',

  View_Shared_inbox = 'View_Shared_inbox',
  View_ECopies_Inbox = 'View_ECopies_Inbox',
  View_Manager_Inbox = 'View_Manager_Inbox',

  View_Users = 'View_Users',
  Add_Users = 'Add_Users',
  Edit_Users = 'Edit_Users',
  Delete_Users = 'Delete_Users',
  Active_DeActivate_Users = 'Active_DeActivate_Users',
  Add_Roles_To_User = 'Add_Roles_To_User',

  View_Roles = 'View_Roles',
  Add_Roles = 'Add_Roles',
  Edit_Roles = 'Edit_Roles',
  Delete_Roles = 'Delete_Roles',
  Active_DeActivate_Roles = 'Active_DeActivate_Roles',

  View_Organization_Structure = 'View_Organization_Structure',
  Add_Departments = 'Add_Departments',
  Edit_Departments = 'Edit_Departments',
  Delete_Departments = 'Delete_Departments',
  Transfer_Emplyee_From_Department = 'Transfer_Emplyee_From_Department',
  View_Emplyee_From_Department = 'View_Emplyee_From_Department',
  Delete_Emplyee_From_Department = 'Delete_Emplyee_From_Department',

  OutboundTransactions_Access = 'OutboundTransactions_Access',
  Create_Outward = 'Create_Outward',
  Edit_Outward = 'Edit_Outward',
  Last_Outward_Copying = 'Last_Outward_Copying',
  Create_Letter_Draft = 'Create_Letter_Draft',
  Edit_Letter_Draft = 'Edit_Letter_Draft',
  Create_Outward_From_Letter_Draft = 'Create_Outward_From_Letter_Draft',
  Create_Letter_Draft_Inward_or_Internal = 'Create_Letter_Draft_Inward_or_Internal',
  Delete_Letter_Draft = 'Delete_Letter_Draft',

  InboudTransactions_Access = 'InboudTransactions_Access',
  Create_Inward = 'Create_Inward',
  Create_Inward_Copying_Last_Inward = 'Create_Inward_Copying_Last_Inward',
  Edit_Inward = 'Edit_Inward',

  InternalTransactions_Access = 'InternalTransactions_Access',
  Create_Internal = 'Create_Internal',
  Create_Internal_ECopy = 'Create_Internal_ECopy',
  Create_Internal_Copyig_Last_Internal = 'Create_Internal_Copyig_Last_Internal',
  Edit_Internal = 'Edit_Internal',

  TransactionRecord_Access = 'TransactionRecord_Access',
  View_Stakeholders_Information_Record = 'View_Stakeholders_Information_Record',
  View_Transactions_Assignment_Record = 'View_Transactions_Assignment_Record',
  View_Transation_Letter_Attachements_Record = 'View_Transation_Letter_Attachements_Record',
  Internal_ECopies_Record = 'Internal_ECopies_Record',
  External_ECopies_Record = 'External_ECopies_Record',
  Linked_Transactions_Record = 'Linked_Transactions_Record',
  Explanations_Record = 'Explanations_Record',
  FollowUp_Record = 'FollowUp_Record',
  TasksRecord = 'TasksRecord',
  Transaction_Tracking = 'Transaction_Tracking',
  Transaction_Modification = 'Transaction_Modification',
  Transaction_Save_Modification = 'Transaction_Save_Modification',

  ConfidentialityAccessing_Normal = 'ConfidentialityAccessing_Normal',
  ConfidentialityAccessing_Secret = 'ConfidentialityAccessing_Secret',
  ConfidentialityAccessing_TopSecret = 'ConfidentialityAccessing_TopSecret',
  ConfidentialityAccessing_ByHand = 'ConfidentialityAccessing_ByHand',

  Transaction_Send = 'Transaction_Send',
  Transaction_Forword = 'Transaction_Forword',
  Transaction_Rollback = 'Transaction_Rollback',
  Transaction_UnSaveTransaction = 'Transaction_UnSaveTransaction',
  Transaction_SendCopy = 'Transaction_SendCopy',
  Transaction_Received = 'Transaction_Received',
  Transaction_Reject = 'Transaction_Reject',
  Transaction_Archive = 'Transaction_Archive',
  Transaction_Share = 'Transaction_Share',
  Transaction_Copy_UnSeen = 'Transaction_Copy_UnSeen',

  AdvancedSearch = 'AdvancedSearch',
  SearchReports = 'SearchReports',

  View_Group = 'View_Group',
  Add_Value_to_Group = 'Add_Value_to_Group',
  Edit_Group = 'Edit_Group',
  Delete_Value_from_Group = 'Delete_Value_from_Group',

  SearchDeliveryStatement = 'SearchDeliveryStatement',
  SearchPrintedDeliveryStatement = 'SearchPrintedDeliveryStatement',
  SearchArchivedDeliveryStatement = 'SearchArchivedDeliveryStatement',
  PrintDeliveryStatement = 'PrintDeliveryStatement',
  ArchivedDeliveryStatement = 'ArchivedDeliveryStatement',

  // delegations
  View_Delegation = 'View_Delegation',
  Add_Value_to_Delegation = 'Add_Value_to_Delegation',
  Edit_Delegation = 'Edit_Delegation',
  Delete_Value_from_Delegation = 'Delete_Value_from_Delegation',

  // charts
  View_Organization_Chart = 'View_Organization_Chart',
  View_SubDepartment_Chart = 'View_SubDepartment_Chart',
  View_Department_Users_Chart = 'View_Department_Users_Chart',
  View_User_Chart = 'View_User_Chart',

  //BCC
  Transaction_MAKE_BCC = 'Transaction_MAKE_BCC',
  Transaction_View_BCC_IN_Certificate = 'Transaction_View_BCC_IN_Certificate',

  //delete
  Transaction_OutDelete = 'Transaction_OutDelete',
  Transaction_Delete = 'Transaction_Delete',

  //rollback after view
  Transaction_SpecialUnSend = 'Transaction_SpecialUnSend',

  //statistics
  AnalyticsDashboard = 'AnalyticsDashboard',

  //editTransactionAfterSend

  Transaction_EditForce = 'Transaction_EditForce',

  Transaction_Explanations_log = 'Transaction_Explanations_log',

  Transaction_Views = 'Transaction_Views',

  Transaction_History = 'Transaction_History',
  Transaction_Referrals = 'Transaction_Referrals',

  TransactionPrint = 'TransactionPrint',
  AttchmentsViewWithoutWaterMark = 'AttchmentsViewWithoutWaterMark',

  View_ReservedTransactionNumber = 'View_ReservedTransactionNumber',
}
