import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { permissionsStrings } from '../../enums/premission';
import { AuthenticationService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { HelperService } from '../../helpers/helper.service';

@Component({
  selector: 'app-user-notifications-settings',
  templateUrl: './user-notifications-settings.component.html',
  styleUrls: ['./user-notifications-settings.component.scss'],
})
export class UserNotificationsSettingsComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private httpClient: HttpClient,
    private helperService: HelperService,
  ) {}
  apiUrl = environment.apiUrl;
  @Input() userName;
  @Input() userRole;

  pushFields = [
    'pushNotificationNewTransactions',
    'pushNotificationNewTasks',
    'pushNotificationFollowUp',
    'pushNotificationSharedTransactions',
    'pushNotificationTransactionGeneral',
    'pushNotificationTransactionCopy',
    'pushNotificationTaskClosed',
    'pushNotificationAlarmRequest',
    'pushNotificationExtendRequest',
  ];

  emailFields = [
    'emailNewTransactions',
    'emailNewTasks',
    'emailFollowUp',
    'emailSharedTransactions',
    // 'emailTransactionGeneral',
    'emailTransactionCopy',
    'emailTaskClosed',
    'emailAlarmRequest',
    'emailExtendRequest',
  ];

  smsFields = [
    'smsNewTransactions',
    'smsNewTasks',
    'smsFollowUp',
    'smsSharedTransactions',
    // 'smsTransactionGeneral',
    'smsTransactionCopy',
    'smsTaskClosed',
    'smsAlarmRequest',
    'smsExtendRequest',
  ];

  ngOnInit(): void {
    this.initForm();

    this.httpClient
      .post(
        `${this.apiUrl}Account/GetNotificationConfigurationNotification`,
        {},
        { headers: this.authService.authHeader },
      )
      .subscribe((res: any) => {
        this.notificationsForm.patchValue(res);
      });
  }

  notificationsForm: FormGroup;

  initForm() {
    this.notificationsForm = new FormGroup({
      // pushNotification
      pushNotificationNewTransactions: new FormControl(true),
      pushNotificationNewTasks: new FormControl(true),
      pushNotificationFollowUp: new FormControl(true),
      pushNotificationSharedTransactions: new FormControl(true),
      pushNotificationTransactionGeneral: new FormControl(true),
      pushNotificationTransactionCopy: new FormControl(true),
      pushNotificationTaskClosed: new FormControl(true),
      pushNotificationAlarmRequest: new FormControl(true),
      pushNotificationExtendRequest: new FormControl(true),
      // pushNotification

      // sms
      smsNewTransactions: new FormControl(true),
      smsNewTasks: new FormControl(true),
      smsFollowUp: new FormControl(true),
      smsSharedTransactions: new FormControl(true),
      // smsTransactionGeneral: new FormControl(true),
      smsTransactionCopy: new FormControl(true),
      smsTaskClosed: new FormControl(true),
      smsAlarmRequest: new FormControl(true),
      smsExtendRequest: new FormControl(true),
      // sms

      //email
      emailNewTransactions: new FormControl(true),
      emailNewTasks: new FormControl(true),
      emailFollowUp: new FormControl(true),
      emailSharedTransactions: new FormControl(true),
      // emailTransactionGeneral: new FormControl(true),
      emailTransactionCopy: new FormControl(true),
      emailTaskClosed: new FormControl(true),
      emailAlarmRequest: new FormControl(true),
      emailExtendRequest: new FormControl(true),
      //email
    });
  }

  saveConfig() {
    this.httpClient
      .post(
        `${this.apiUrl}Account/CreateOrUpdateConfiguration`,
        this.notificationsForm.value,
        { headers: this.authService.authHeader },
      )
      .subscribe(
        (res: any) => {
          this.helperService.handleSuccess(res);
        },
        (error: any) => {
          this.helperService.handleError(error);
        },
      );
  }

  isChecked(type: string) {
    switch (type) {
      case 'sms':
        return this.smsFields.every(
          (item) => this.notificationsForm.value[`${item}`],
        );

      case 'push':
        return this.pushFields.every(
          (item) => this.notificationsForm.value[`${item}`],
        );

      case 'email':
        return this.emailFields.every(
          (item) => this.notificationsForm.value[`${item}`],
        );

      default:
        return false;
    }
  }

  changeCheckALl(type: string, $event: any) {
    const allChecked = $event.target.checked;

    switch (type) {
      case 'sms':
        this.notificationsForm.patchValue({
          smsNewTransactions: allChecked,
          smsNewTasks: allChecked,
          smsFollowUp: allChecked,
          smsSharedTransactions: allChecked,
          // smsTransactionGeneral: allChecked,
          smsTransactionCopy: allChecked,
          smsTaskClosed: allChecked,
          smsAlarmRequest: allChecked,
          smsExtendRequest: allChecked,
        });
        break;
      case 'push':
        this.notificationsForm.patchValue({
          pushNotificationNewTransactions: allChecked,
          pushNotificationNewTasks: allChecked,
          pushNotificationFollowUp: allChecked,
          pushNotificationSharedTransactions: allChecked,
          pushNotificationTransactionGeneral: allChecked,
          pushNotificationTransactionCopy: allChecked,
          pushNotificationTaskClosed: allChecked,
          pushNotificationAlarmRequest: allChecked,
          pushNotificationExtendRequest: allChecked,
        });
        break;

      case 'email':
        this.notificationsForm.patchValue({
          emailNewTransactions: allChecked,
          emailNewTasks: allChecked,
          emailFollowUp: allChecked,
          emailSharedTransactions: allChecked,
          // emailTransactionGeneral: allChecked,
          emailTransactionCopy: allChecked,
          emailTaskClosed: allChecked,
          emailAlarmRequest: allChecked,
          emailExtendRequest: allChecked,
        });
        break;

      default:
        return false;
    }
  }
}
