export const environment = {
  production: false,
  applicationServerPublicKey: `BFWED1rTkqUDcE6iJBMmROl4VvR-TGNs_yKo2O5GEEI5FpDt1wonDEaW92-RD2gto_2G2RI_04Zi8Z4SCu1aTmA`,
  apiUrl: 'https://stg.api.signalx.42.solutions/api/',
  serverUrl: 'https://stg.signalx.42.solutions',
  scannerProductKey:
    't01539gIAAB1lI0Zt85KPa0M9LTL8HbVX1R9KPXeqw+IBFcRRHp3FaY8+E+B1Q1PmuXPaNj9VhKY7GDdSiA+e+6oHpZrN4ewbqrlhDAxWF3WvZFMJhjfwcCAdIBY5An/lBzzlczuqEABnQBLg3fgDzkN+e8WDkBFwBiQB5yEDGHSy2jeVl4BaD0lDwBmQBPSQAehi0Za5vgHleJpC',
  apiCounter: 1000,
  clientBrandImage: '/assets/images/logos-darat/logobig-removebg.png',
  clientBrandImageSm: '/assets/images/logos-darat/logosm-removebg.png',
  syncLicense:
    'ORg4AjUWIQA/Gnt2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkdjXH1acXRRR2Vb',
  generalSyncToolbar: ['OpenOption', 'PrintOption', 'DownloadOption'],
  searchSyncToolbarInbox: [
    'OpenOption',
    'PanTool',
    'SelectionTool',
    'PrintOption',
    'DownloadOption',
    'SubmitForm',
    'FormDesignerEditTool',
  ],
  searchSyncToolbar: [
    'OpenOption',
    'PanTool',
    'SelectionTool',
    'SearchOption',
    'PrintOption',
    'DownloadOption',
    'AnnotationEditTool',
    'FormDesignerEditTool',
    'CommentTool',
    'SubmitForm',
  ],
  SyncService: 'https://pdf.stackanalytix.com/pdfviewer',
  documentService: 'https://pdf.stackanalytix.com/editor/api/DocumentEditor/',
  showUserPassLogin: true,
};
