import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import printJS from 'print-js';
import { TransactionService } from '../services/transaction.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private toasterService: ToastrService,
    private router: Router,
    private transactionService: TransactionService,
  ) {}

  getFormData(object, useArray?: boolean, fieldName?: string) {
    const formData = new FormData();

    Object?.keys(object)?.map((key) => {
      if (Array.isArray(object[key]) && useArray && fieldName === key) {
        const details = object[key];

        // do somthing
        details.map((variant, index) => {
          Object.keys(variant).map((variantKey) => {
            const value =
              typeof variant[variantKey] === 'string'
                ? variant[variantKey].replace(/\s+/g, ' ')
                : variant[variantKey];
            formData.append(`${key}[${index}][${variantKey}]`, value);
          });
        });
      } else {
        const value =
          typeof object[key] === 'string'
            ? object[key].replace(/\s+/g, ' ')
            : object[key];

        // formData.append(key, value);
        // Display the key/value pairs

        const ext = `${value?.type?.split('/')[1]}`;

        // console.log('ext', ext);
        let fileName;

        switch (ext) {
          case 'msword':
            fileName = `file.doc`;
            break;
          case 'vnd.ms-powerpoint':
            fileName = `file.ppt`;
            break;
          case 'vnd.ms-excel':
            fileName = `file.xls`;
            break;
          case 'vnd.openxmlformats-officedocument.presentationml.presentation':
            fileName = `file.ppt`;
            break;

          case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            fileName = `file.xls`;
            break;

          default:
            fileName = `file.${ext}`;
            break;
        }

        // console.log(fileName);

        formData?.append(key, value, fileName);

        // formData.append(
        //   key,
        //   value,
        //   ext.split('/')[1] === 'msword'
        //     ? `file.doc`
        //     : `file.${ext.split('/')[1]}`,
        // );

        // for (const pair of formData.entries()) {
        //   //console.log(pair);
        //   //console.log(pair[0] + ', ' + pair[1]);
        //   //console.log(pair[1]);
        // }
      }
    });

    return formData;
  }

  showMessage(type: string, title: string, message: string) {
    const isAr = localStorage.getItem('lang') === 'ar';
    const position: any = isAr ? 'topLeft' : 'topRight';
    if (type === 'error') {
      this.toasterService.error(title, message);
    } else if (type === 'success') {
      this.toasterService.success(title, message);
    }
  }

  showApiSuccess(res: any) {
    const isAr = localStorage.getItem('lang') === 'ar';
    const position: any = isAr ? 'topLeft' : 'topRight';
    //console.log(res);

    this.toasterService.success(
      `${isAr ? 'عملية ناجحة!' : 'Success'}`,
      `${isAr ? res['message_AR'] : res['message_EN']}`,
    );
  }

  showApiError(res: any) {
    const isAr = localStorage.getItem('lang') === 'ar';
    const position: any = isAr ? 'topLeft' : 'topRight';
    this.toasterService.error(
      `${isAr ? 'خطأ' : 'error'}`,
      `${isAr ? res['message_AR'] : res['message_EN']}`,
    );
  }

  getFormDataFiles(object) {
    const formData = new FormData();
    Object.keys(object).map((key, index) => {
      const value =
        typeof object[key] === 'string'
          ? object[key].replace(/\s+/g, ' ')
          : object[key];
      value.map((item, index) => {
        formData.append(`${key}`, item);
      });
    });
    return formData;
  }

  handleSuccess(res) {
    this.toasterService.success(res?.message, 'عملية ناجحة!');
  }
  handleNote(res, time) {
    this.toasterService.success(res?.message, 'تنبيه !', {
      timeOut: time,
    });
  }

  handleError(res) {
    if (res?.error?.message) {
      this.toasterService.error(res?.error?.message, 'تنبيه !');
    } else {
      this.toasterService.error(res?.message, 'تنبيه !');
    }
  }

  generateMessage(type?: string, message?: string) {
    if (type === 'error') {
      return {
        error: {
          message: message,
        },
      };
    } else {
      return {
        message: message,
      };
    }
  }

  buildQuery(model: Object) {
    const query: NavigationExtras = {
      queryParams: model,
    };

    const queryTree = this.router.createUrlTree([''], query);
    const queryUrl = this.router.serializeUrl(queryTree).substring(1);

    return queryUrl;
  }

  printFileDialog(file) {
    if (file) {
      const blobURL = URL.createObjectURL(file);
      printJS(blobURL);
    }
  }

  printFileWithoutWaterMark(fileId) {
    this.transactionService.DownloadFile(fileId, 'no').subscribe(
      async (res: any) => {
        const arr = new Uint8Array(res);
        // final blob
        const blob = new Blob([arr], { type: 'application/pdf' });
        const blobURL = URL.createObjectURL(blob);
        printJS(blobURL);
      },
      (error) => {
        this.handleError(error);
      },
    );
  }
}
