import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HijiryDateService {
  constructor() {}

  gmod(n, m) {
    return ((n % m) + m) % m;
  }

  kuwaiticalendar(date, adjust) {
    const today = date ? new Date(+date) : new Date();
    if (adjust) {
      today.setDate(today.getDate() + +adjust);
    }

    let day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    let m = month + 1;
    let y = year;

    if (m < 3) {
      y -= 1;
      m += 12;
    }

    let a = Math.floor(y / 100);
    let b = 2 - a + Math.floor(a / 4);

    if (y < 1583) b = 0;
    if (y === 1582) {
      if (m > 10) b = -10;
      if (m === 10) {
        b = 0;
        if (day > 4) b = -10;
      }
    }

    const jd =
      Math.floor(365.25 * (y + 4716)) +
      Math.floor(30.6001 * (m + 1)) +
      day +
      b -
      1524;

    b = 0;
    if (jd > 2299160) {
      a = Math.floor((jd - 1867216.25) / 36524.25);
      b = 1 + a - Math.floor(a / 4);
    }

    const bb = jd + b + 1524;
    let cc = Math.floor((bb - 122.1) / 365.25);
    const dd = Math.floor(365.25 * cc);
    const ee = Math.floor((bb - dd) / 30.6001);
    day = bb - dd - Math.floor(30.6001 * ee);
    month = ee - 1;

    if (ee > 13) {
      cc += 1;
      month = ee - 13;
    }
    year = cc - 4716;
    const wd = this.gmod(jd + 1, 7) + 1;

    const iyear = 10631 / 30;
    const epochastro = 1948084;
    const epochcivil = 1948085;

    const shift1 = 8.01 / 60;

    let z = jd - epochastro;
    const cyc = Math.floor(z / 10631);
    z = z - 10631 * cyc;
    const j = Math.floor((z - shift1) / iyear);
    const iy = 30 * cyc + j;
    z = z - Math.floor(j * iyear + shift1);
    let im = Math.floor((z + 28.5001) / 29.5);

    if (im === 13) im = 12;
    const id = z - Math.floor(29.5001 * im - 29);

    const final = {
      day, //calculated day (CE)
      month: month - 1, //calculated month (CE)
      year, //calculated year (CE)
      jd: jd - 1, //julian day number
      wd: wd - 1, //weekday number
      id, //islamic date
      // im - 1, //islamic month
      im: im - 1, //islamic month
      iy, //islamic year
    };

    return `${final.iy}-${this.formatDate(final.im + 1)}-${this.formatDate(
      final.id + 1,
    )}`;
  }

  kuwaiticalendarObject(date, adjust) {
    const today = date ? new Date(+date) : new Date();
    if (adjust) {
      today.setDate(today.getDate() + +adjust);
    }

    let day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    let m = month + 1;
    let y = year;

    if (m < 3) {
      y -= 1;
      m += 12;
    }

    let a = Math.floor(y / 100);
    let b = 2 - a + Math.floor(a / 4);

    if (y < 1583) b = 0;
    if (y === 1582) {
      if (m > 10) b = -10;
      if (m === 10) {
        b = 0;
        if (day > 4) b = -10;
      }
    }

    const jd =
      Math.floor(365.25 * (y + 4716)) +
      Math.floor(30.6001 * (m + 1)) +
      day +
      b -
      1524;

    b = 0;
    if (jd > 2299160) {
      a = Math.floor((jd - 1867216.25) / 36524.25);
      b = 1 + a - Math.floor(a / 4);
    }

    const bb = jd + b + 1524;
    let cc = Math.floor((bb - 122.1) / 365.25);
    const dd = Math.floor(365.25 * cc);
    const ee = Math.floor((bb - dd) / 30.6001);
    day = bb - dd - Math.floor(30.6001 * ee);
    month = ee - 1;

    if (ee > 13) {
      cc += 1;
      month = ee - 13;
    }
    year = cc - 4716;
    const wd = this.gmod(jd + 1, 7) + 1;

    const iyear = 10631 / 30;
    const epochastro = 1948084;
    const epochcivil = 1948085;

    const shift1 = 8.01 / 60;

    let z = jd - epochastro;
    const cyc = Math.floor(z / 10631);
    z = z - 10631 * cyc;
    const j = Math.floor((z - shift1) / iyear);
    const iy = 30 * cyc + j;
    z = z - Math.floor(j * iyear + shift1);
    let im = Math.floor((z + 28.5001) / 29.5);

    if (im === 13) im = 12;
    const id = z - Math.floor(29.5001 * im - 29);

    const final = {
      day, //calculated day (CE)
      month: month - 1, //calculated month (CE)
      year, //calculated year (CE)
      jd: jd - 1, //julian day number
      wd: wd - 1, //weekday number
      id, //islamic date
      // im - 1, //islamic month
      im: im - 1, //islamic month
      iy, //islamic year
    };

    return {
      year: iy,
      month: im,
      day: id,
    };
  }

  kuwaiticalendarArray(date, adjust) {
    const today = date ? new Date(+date) : new Date();
    if (adjust) {
      today.setDate(today.getDate() + +adjust);
    }

    let day = today.getDate();
    let month = today.getMonth();
    let year = today.getFullYear();
    let m = month + 1;
    let y = year;

    if (m < 3) {
      y -= 1;
      m += 12;
    }

    let a = Math.floor(y / 100);
    let b = 2 - a + Math.floor(a / 4);

    if (y < 1583) b = 0;
    if (y === 1582) {
      if (m > 10) b = -10;
      if (m === 10) {
        b = 0;
        if (day > 4) b = -10;
      }
    }

    const jd =
      Math.floor(365.25 * (y + 4716)) +
      Math.floor(30.6001 * (m + 1)) +
      day +
      b -
      1524;

    b = 0;
    if (jd > 2299160) {
      a = Math.floor((jd - 1867216.25) / 36524.25);
      b = 1 + a - Math.floor(a / 4);
    }

    const bb = jd + b + 1524;
    let cc = Math.floor((bb - 122.1) / 365.25);
    const dd = Math.floor(365.25 * cc);
    const ee = Math.floor((bb - dd) / 30.6001);
    day = bb - dd - Math.floor(30.6001 * ee);
    month = ee - 1;

    if (ee > 13) {
      cc += 1;
      month = ee - 13;
    }
    year = cc - 4716;
    const wd = this.gmod(jd + 1, 7) + 1;

    const iyear = 10631 / 30;
    const epochastro = 1948084;
    const epochcivil = 1948085;

    const shift1 = 8.01 / 60;

    let z = jd - epochastro;
    const cyc = Math.floor(z / 10631);
    z = z - 10631 * cyc;
    const j = Math.floor((z - shift1) / iyear);
    const iy = 30 * cyc + j;
    z = z - Math.floor(j * iyear + shift1);
    let im = Math.floor((z + 28.5001) / 29.5);

    if (im === 13) im = 12;
    const id = z - Math.floor(29.5001 * im - 29);

    return [
      day, //calculated day (CE)
      month - 1, //calculated month (CE)
      year, //calculated year (CE)
      jd - 1, //julian day number
      wd - 1, //weekday number
      id, //islamic date
      im - 1, //islamic month
      iy, //islamic year
    ];
  }

  writeIslamicDate(date, adjustment) {
    const wdNames = [
      'Ahad',
      'Ithnin',
      'Thulatha',
      'Arbaa',
      'Khams',
      'Jumuah',
      'Sabt',
    ];
    const iMonthNames = [
      'Muharram',
      'Safar',
      "Rabi'ul Awwal",
      "Rabi'ul Akhir",
      'Jumadal Ula',
      'Jumadal Akhira',
      'Rajab',
      "Sha'ban",
      'Ramadan',
      'Shawwal',
      "Dhul Qa'ada",
      'Dhul Hijja',
    ];
    const iDate = this.kuwaiticalendarArray(date, adjustment);

    const outputIslamicDate =
      wdNames[iDate[4]] +
      ', ' +
      iDate[5] +
      ' ' +
      iMonthNames[iDate[6]] +
      ' ' +
      iDate[7] +
      ' AH';
    return outputIslamicDate;
  }

  writeIslamicDateReady(date, adjustment, time?) {
    const wdNames = [
      'Ahad',
      'Ithnin',
      'Thulatha',
      'Arbaa',
      'Khams',
      'Jumuah',
      'Sabt',
    ];

    const iMonthNames = [
      'محرم',
      'صفر',
      'ربيع الأول',
      'ربيع الآخر',
      'جماد الأول',
      'جماد الآخر',
      'رجب',
      'شعبان',
      'رمضان',
      'شوال',
      'ذو القعدة',
      'ذو الحجة',
    ];

    // const iMonthNames = [
    //   'Muharram',
    //   'Safar',
    //   "Rabi'ul Awwal",
    //   "Rabi'ul Akhir",
    //   'Jumadal Ula',
    //   'Jumadal Akhira',
    //   'Rajab',
    //   "Sha'ban",
    //   'Ramadan',
    //   'Shawwal',
    //   "Dhul Qa'ada",
    //   'Dhul Hijja',
    // ];

    const iDate = date;

    const outputIslamicDate = `${
      iDate[0] + ' , ' + iMonthNames[iDate[1] - 1] + '  ' + +iDate[2]
    }`;

    // return time with date
    if (time) {
      const [hours, minutes, seconds] = time.split(':');
      const dateT = new Date();
      dateT.setHours(hours);
      dateT.setMinutes(minutes);
      dateT.setSeconds(seconds);

      const formattedTime = dateT.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });

      return outputIslamicDate + ' ' + formattedTime;
    }
    return outputIslamicDate;
  }

  // date is optional, defaults to today
  writeHijri(dateV, lang) {
    const date = dateV || new Date();
    lang = lang || 'en';
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleString(lang + '-u-ca-islamic', options);
  }

  formatDate(value: any) {
    if (typeof value === 'string' || typeof value === 'number') {
      const string = value.toString();
      if (string.length === 1) {
        return `0${value}`;
      } else {
        return value;
      }
    }
  }
}
